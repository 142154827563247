import { Button } from '@interstate/components/Button'
import React from 'react'

export function MockUserForm({ username, title }) {
    return (
        <form method="POST" action="/auth/login/password">
            <br />
            <input type="hidden" name="username" value={username} />
            <input
                type="hidden"
                name="password"
                value={(Math.random() + 1).toString(36).substring(7)}
            />
            <Button buttonStyle="secondary" type="submit">
                {username}
            </Button>
        </form>
    )
}
