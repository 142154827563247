import { React } from 'react'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import { useSearchParams } from 'react-router-dom'
import { useAuditRowById } from '../service/auditService'
import { IndividualAudit } from '../components/audit/IndividualAudit'

export function SearchTransactionDetailsView() {
    const [queryParams] = useSearchParams()

    let auditId = queryParams.get('auditId')
    const { value: auditRowData, loading: auditRowLoading } =
        useAuditRowById(auditId)
    let auditRowArray = [auditRowData]

    return (
        <Container>
            <PageHeader titleValue="Transactions Details" />
            {!auditRowLoading && auditRowData && (
                <div>
                    <IndividualAudit
                        auditData={auditRowArray}
                        back={undefined}
                    />
                </div>
            )}
        </Container>
    )
}
