import { Table } from '@interstate/components/Table'
import React from 'react'
import { renderDateFromIsoString } from '../../utils/Formatters'

export function AppliedFeeTable({
    sbluAuditRecords,
    sbluAuditRecordsLoading,
    sbluAuditRecordsError,
    manualFees
}) {
    function getManualFee(feeCategory) {
        return manualFees?.find(
            (eachManualFee) => eachManualFee.fee_category === feeCategory
        )
    }

    function displayPatCalculatedFee(sbluAuditRecord) {
        let manuallyEnteredFee = getManualFee(sbluAuditRecord.feeCategory)
        if (
            manuallyEnteredFee &&
            manuallyEnteredFee.amount !== sbluAuditRecord.patCalculatedFee
        ) {
            return (
                <span>
                    {sbluAuditRecord.patCalculatedFee}
                    <br />
                    <span>(Manually Entered: {manuallyEnteredFee.amount})</span>
                </span>
            )
        }
        return <span>{sbluAuditRecord.patCalculatedFee}</span>
    }

    return (
        <>
            <h2>Applied Fees</h2>
            {sbluAuditRecordsError && (
                <div>
                    <h3>Error</h3>
                    <p>{sbluAuditRecordsError.message}</p>
                </div>
            )}
            {!sbluAuditRecordsLoading && (
                <Table
                    tableLayout={'auto'}
                    columns={[
                        {
                            title: 'Fee Category',
                            render: (item) => <span>{item.feeCategory}</span>
                        },
                        {
                            title: 'Sale Type',
                            render: (item) => <span>{item.saleType}</span>
                        },
                        {
                            title: 'Fee Schedule Type',
                            render: (item) => <span>{item.contractType}</span>
                        },
                        {
                            title: 'Fee Type',
                            render: (item) => <span>{item.feeType}</span>
                        },
                        {
                            title: 'Amount',
                            render: (item) => displayPatCalculatedFee(item)
                        },
                        {
                            title: 'Previous Amount',
                            render: (item) => (
                                <span>{item.as400CalculatedFee}</span>
                            )
                        },
                        {
                            title: 'Write Back',
                            render: (item) => (
                                <span>{item.writeback ? 'true' : 'false'}</span>
                            )
                        },
                        {
                            title: 'Fee Changed',
                            render: (item) => (
                                <span>
                                    {item.feeChanged ? 'true' : 'false'}
                                </span>
                            )
                        },
                        {
                            title: 'Created On',
                            render: (item) => (
                                <span>
                                    {renderDateFromIsoString(
                                        item.created,
                                        'MM/DD/YYYY'
                                    )}
                                </span>
                            )
                        },
                        {
                            title: 'FeeID',
                            render: (item) => (
                                <span>{item.feeSchedulePartId}</span>
                            )
                        }
                    ]}
                    data={sbluAuditRecords}
                />
            )}
        </>
    )
}
