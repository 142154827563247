import moment from 'moment'

const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

function trimString(str) {
    return String(str).trim()
}

/**
 * String can be null or empty
 * @param val
 * @returns {boolean}
 */
export function isEmptyString(val) {
    if (val && trimString(val).length > 0) {
        return false
    } else if (!val || val?.length === 0) {
        return true
    }
    return true
}

/*
 * String can be empty, but not null or undefined
 * */
export function isEmptyNotNullString(val) {
    if (val === null || val === undefined) {
        return false
    } else if (trimString(val).length === 0) {
        return true
    } else if (trimString(val) > 0) {
        return false
    }
}

/**
 * String cannot be null or empty
 * @param val
 * @returns {boolean}
 */
export function isNullOrEmptyString(val) {
    if (val === null || val === undefined || trimString(val).length === 0) {
        return true
    }
    return false
}

export function isEmptyArray(arr) {
    if (arr && arr.length > 0) {
        return false
    }
    return true
}

export function isEmptyStringArray(arr) {
    if (arr && arr.length > 0 && arr[0].trim().length > 0) {
        return false
    }
    return true
}

export function isEmptyArrayForAuctions(arr) {
    if (!arr || arr.length === 0 || arr[0] === 'ALL') {
        return true
    }
    return false
}

export function isNumeric(str) {
    return !isNaN(parseFloat(str)) && isFinite(str)
}

export function isValidEmail(email) {
    return emailRegEx.test(email)
}

export function nonEmptyErrors(errors) {
    if (Object.keys(errors).length === 0) {
        return null
    }
    return errors
}

export function isDateAfterToday(dateString) {
    return moment(dateString).isAfter(moment())
}

export function isEqualByIgnoringNull(
    val1Str,
    val2Str,
    feesCompared,
    rateCardId
) {
    // console.log(
    //     'val1Str',
    //     val1Str,
    //     'val2Str',
    //     val2Str,
    //     'feesCompared',
    //     feesCompared,
    //     'rateCardId',
    //     rateCardId
    // )
    if (!val1Str) {
        val1Str = ''
    }
    if (!val2Str) {
        val2Str = ''
    }
    let val1 = 0
    if (val1Str.length > 0) {
        // if the value is a string and not a number, then convert it to a number
        val1 = parseInt(val1Str)
    } else {
        val1 = val1Str
    }

    let val2 = 0
    if (val2Str.length > 0) {
        val2 = parseInt(val2Str)
    } else {
        val2 = val2Str
    }
    let retVal = val1 === val2
    // console.log('retVal', retVal)
    return retVal
}
