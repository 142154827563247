import { useAuditSearch } from '../../service/auditService'
import { React, useEffect, useState } from 'react'
import { Table } from '@interstate/components/Table'
import { IndividualAudit } from '../audit/IndividualAudit'
import { PageHeader } from '../PageHeader'
import { PAT_ROLE } from '../../resources/enum.constants'
import { useAuth } from '../../auth/AuthProvider'
// import { useNavigate } from 'react-router-dom'

export function SearchTransactionResults({ query }) {
    const authService = useAuth()
    const principal = authService?.principal
    let userRole = principal?.role

    // const navigate = useNavigate()
    const {
        value: searchResults,
        loading: searchResultsLoading,
        error: searchResultsError
    } = useAuditSearch(query)

    const [selectedAudit, setSelectedAudit] = useState(null)
    const [mapData, setMapData] = useState([])

    useEffect(() => {
        let data = searchResults || []

        data = data.map((item) => {
            item['key'] = item.sblu + item.feeType
            return item
        })

        let ndata = {}
        data.forEach((item) => {
            let key = item.sblu + item.buCode
            if (ndata[key]) {
                ndata[key].push(item)
            } else {
                ndata[key] = [item]
            }
        })
        setMapData(ndata)
        return () => {
            setSelectedAudit(null)
        }
    }, [searchResults])

    return (
        <div>
            <br />
            {searchResultsLoading && <p>Loading...</p>}
            {searchResultsError && <p>Error</p>}
            {searchResults && (
                <>
                    {!selectedAudit && (
                        <>
                            <PageHeader
                                titleValue={
                                    'Results: ' + Object.keys(mapData).length
                                }
                            />
                            <Table
                                tableLayout="fixed"
                                // sortableColumns={true}
                                dataDensity="small"
                                // displayFilter={true}
                                // highlightOnHover={true}
                                headerBackgroundColor="dark"
                                rowClassName={(record) => {
                                    let reply = 'tableGood'
                                    mapData[record]?.forEach((item) => {
                                        if (
                                            item.as400CalculatedFee !==
                                            item.patCalculatedFee
                                        ) {
                                            reply = 'tableBad'
                                        }
                                    })
                                    return reply
                                }}
                                columns={[
                                    {
                                        title: 'Index',
                                        render: (item) => (
                                            <span>
                                                <div>
                                                    <b>Location:</b>{' '}
                                                    {mapData[item][0]?.buCode}{' '}
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setSelectedAudit(item)
                                                    }}>
                                                    <b>SBLU:</b>{' '}
                                                    {mapData[item][0]?.sblu}
                                                </div>
                                                <div>
                                                    <b>WO#:</b>{' '}
                                                    {
                                                        mapData[item][0]
                                                            ?.workOrderNumber
                                                    }
                                                </div>
                                                <div>
                                                    <b>UKEY:</b>{' '}
                                                    {mapData[item][0]?.saleYear}
                                                    -
                                                    {
                                                        mapData[item][0]
                                                            ?.saleNumber
                                                    }
                                                    -{mapData[item][0]?.lane}-
                                                    {
                                                        mapData[item][0]
                                                            ?.runNumber
                                                    }
                                                </div>
                                                <div>
                                                    <b>Vin: </b>
                                                    {mapData[item][0]?.vin}
                                                </div>
                                            </span>
                                        )
                                    },
                                    {
                                        title: 'Sale',
                                        render: (item) => (
                                            <span>
                                                <div>
                                                    <b>Contract Type:</b>{' '}
                                                    {
                                                        mapData[item][0]
                                                            ?.contractType
                                                    }
                                                </div>
                                                <div>
                                                    <b>Sale Type:</b>{' '}
                                                    {mapData[item][0]?.saleType}
                                                </div>
                                                <div>
                                                    <b>Buyer Table:</b>{' '}
                                                    {
                                                        mapData[item][0]
                                                            ?.buyerFeeTable
                                                    }
                                                </div>
                                                <div>
                                                    <b>Seller Table:</b>{' '}
                                                    {
                                                        mapData[item][0]
                                                            ?.sellerFeeTable
                                                    }
                                                </div>
                                                <div>
                                                    <b>Sale Price: </b>{' '}
                                                    {
                                                        mapData[item][0]
                                                            ?.salePrice
                                                    }
                                                </div>
                                            </span>
                                        )
                                    },
                                    {
                                        title: 'Customer',
                                        render: (item) => (
                                            <span>
                                                <div>
                                                    <b>Buyer / Group / Alt</b>
                                                </div>
                                                <span>
                                                    {mapData[item][0]?.buyer} /{' '}
                                                </span>
                                                <span>
                                                    {
                                                        mapData[item][0]
                                                            ?.buyerGroup
                                                    }{' '}
                                                    /{' '}
                                                </span>
                                                <span>
                                                    {
                                                        mapData[item][0]
                                                            ?.buyerSubGroup
                                                    }
                                                </span>
                                                <div>
                                                    <b>Seller / Group / Alt</b>
                                                </div>
                                                <span>
                                                    {mapData[item][0]?.seller} /{' '}
                                                </span>
                                                <span>
                                                    {
                                                        mapData[item][0]
                                                            ?.sellerGroup
                                                    }{' '}
                                                    /{' '}
                                                </span>
                                                <span>
                                                    {
                                                        mapData[item][0]
                                                            ?.sellerSubGroup
                                                    }
                                                </span>
                                            </span>
                                        )
                                    },
                                    {
                                        title: 'Fees',
                                        render: (item) => (
                                            <span>
                                                <div>
                                                    <b>
                                                        (Fee Type) Pat / AS400
                                                    </b>
                                                </div>
                                                {mapData[item].map((fee) => {
                                                    return (
                                                        <div>
                                                            <span>
                                                                {fee.feeType}{' '}
                                                            </span>
                                                            <span>
                                                                {
                                                                    fee.patCalculatedFee
                                                                }
                                                            </span>
                                                            <span>
                                                                {' '}
                                                                /{' '}
                                                                {
                                                                    fee.as400CalculatedFee
                                                                }
                                                            </span>
                                                        </div>
                                                    )
                                                })}
                                                {userRole ===
                                                    PAT_ROLE.ADMIN && (
                                                    <div>
                                                        <a
                                                            target={'_blank'}
                                                            href={`#/boc/searchTransactionDetails?auditId=${mapData[item][0]?.id}`}
                                                            rel="noreferrer">
                                                            <span>New Tab</span>
                                                        </a>
                                                    </div>
                                                )}
                                            </span>
                                        )
                                    }
                                ]}
                                data={Object.keys(mapData)}
                            />
                        </>
                    )}
                    {selectedAudit && (
                        <div>
                            <IndividualAudit
                                auditData={mapData[selectedAudit]}
                                back={() => setSelectedAudit(null)}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
