import axios from 'axios'
import { useAsyncValue } from '../utils/CustomHooksUtil'

export function useCurrentFeeSchedule(customerIds) {
    return useAsyncValue(() => {
        if (!customerIds?.length) {
            return Promise.resolve(null)
        }
        const queryParams = customerIds.join('&customerId=')
        const endPoint = `/api/feeSchedules/current?customerId=${queryParams}`
        return axios.get(endPoint).then(({ data }) => data)
    }, [customerIds])
}

export function useFeeSchedules(params) {
    return useAsyncValue(() => {
        if (!params?.customerId?.length) {
            return Promise.resolve(null)
        }
        const endPoint = `/api/feeSchedules`
        return axios
            .get(endPoint, {
                params: params,
                paramsSerializer: { indexes: null }
            })
            .then(({ data }) => data)
    }, [
        params.customerId,
        params.startDate,
        params.endDate,
        params.feeScheduleStatus
    ])
}

export function saveNewFeeSchedule(feeSchedule) {
    const endPoint = `/api/feeSchedules`
    return axios.post(endPoint, feeSchedule)
}

export function updateFeeSchedule(feeScheduleFlat) {
    const endPoint = `/api/updateFeeSchedule/id/${feeScheduleFlat.id}`
    return axios.put(endPoint, feeScheduleFlat)
}

export function expireFeeSchedule(feeScheduleFlat) {
    const endPoint = `/api/feeSchedules/expire/id/${feeScheduleFlat.id}`
    return axios.put(endPoint)
}

export function deleteFeeSchedule(feeScheduleFlat) {
    const endPoint = `/api/feeSchedules/delete/id/${feeScheduleFlat.id}`
    return axios.put(endPoint)
}

export function previewFeeChanges(feeSchedule) {
    const endPoint = `/api/feeSchedules/preview`
    return axios.post(endPoint, feeSchedule)
}

export function usePreviewFeeChanges(feeSchedule) {
    return useAsyncValue(() => {
        if (!feeSchedule) {
            return Promise.resolve(null)
        }
        const endPoint = `/api/feeSchedules/preview-fees`
        return axios.post(endPoint, feeSchedule).then(({ data }) => {
            return data
        })
    }, [feeSchedule])
}

export function useFeeScheduleById(feeScheduleId) {
    return useAsyncValue(() => {
        if (!feeScheduleId) {
            return Promise.resolve(null)
        }
        const endPoint = `/api/feeSchedules/id/${feeScheduleId}`
        return axios.get(endPoint).then(({ data }) => {
            console.log('response data', data)
            return data
        })
    }, [feeScheduleId])
}

export function getFeeScheduleByIdList(feeScheduleIdList) {
    return axios
        .post(`/api/feeSchedules/listFeeSchedules`, { feeScheduleIdList })
        .then(({ data }) => data)
}

export function useSearchFee({
    feeScheduleTypes,
    auctionCodes,
    saleTypes,
    feeTypes,
    accounts,
    groupCodes,
    altGroupCodes,
    startDate,
    endDate,
    description,
    includeSaleTypeParent,
    includeDeletedExpired,
    anyCustomersOnly,
    noCustomersOnly
}) {
    return useAsyncValue(() => {
        // if(!feeScheduleId){
        //     return Promise.resolve(null);
        // }
        const endPoint = `/api/feeSchedules/searchFee`
        return axios
            .get(endPoint, {
                params: {
                    feeScheduleTypes,
                    auctionCodes,
                    saleTypes,
                    feeTypes,
                    accounts,
                    groupCodes,
                    altGroupCodes,
                    startDate,
                    endDate,
                    description,
                    includeSaleTypeParent,
                    includeDeletedExpired,
                    anyCustomersOnly,
                    noCustomersOnly
                }
            })
            .then(({ data }) => {
                // console.log('response data', data)
                return data
            })
    }, [
        feeScheduleTypes,
        auctionCodes,
        saleTypes,
        feeTypes,
        accounts,
        groupCodes,
        altGroupCodes,
        startDate,
        endDate,
        description,
        includeSaleTypeParent,
        includeDeletedExpired,
        anyCustomersOnly,
        noCustomersOnly
    ])
}

export function publishFeeSchedule(feeSchedulePublish) {
    const endPoint = `/api/feeSchedules/publish`
    return axios.post(endPoint, feeSchedulePublish).then(({ data }) => data)
}

export function getFeeAuctionSblu(auction, sblu) {
    return axios
        .get(`/api/feeSchedules/getFee?auction=${auction}&sblu=${sblu}`)
        .then(({ data }) => data)
}

export function getFeeCalculated(vehicle) {
    return axios
        .post(`/api/feeSchedules/calculate`, vehicle)
        .then(({ data }) => data)
}

export function importAS400FeeScheduleOnToOne(auctionCode, endDate) {
    const endPoint = `/api/feeSchedules/importAS400FeeScheuduleOneToOne?auctionCode=${auctionCode}&endDate=${endDate}`
    return axios.post(endPoint, {})
}

export function getPossibleFeeSchedules(
    auction,
    customerIds,
    saleType,
    saleDate
) {
    return axios
        .post(`/api/feeSchedules/feelist`, {
            auction,
            customerIds,
            saleType,
            saleDate
        })
        .then(({ data }) => data)
        .catch((error) => {
            console.log(error)
        })
}

export function useFeeScheduleByGroupId(feeScheduleGroupId) {
    return useAsyncValue(() => {
        if (!feeScheduleGroupId) {
            return Promise.resolve(null)
        }
        const endPoint = `/api/feeSchedules/groupId/${feeScheduleGroupId}`
        return axios.get(endPoint).then(({ data }) => {
            return data
        })
    }, [feeScheduleGroupId])
}
