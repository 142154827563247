import { Select } from '@interstate/components/SelectInput'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { FEE_SCHEDULE_TYPE } from '../../resources/enum.constants'
import { FEE_TYPE } from '../../resources/enum.constants'

export function FeeTypesComboBox({
    label = 'Fee Type',
    value,
    onChange,
    multiple = false,
    disabled,
    required,
    dataTestId,
    scheduleFeeType,
    name,
    includeAllSelection = false
}) {
    const staticData = useStaticDataContext()
    let placeholderText = 'Select'
    let configValues = []
    if (includeAllSelection) {
        placeholderText = 'ALL'
        configValues = [
            {
                'data-testid': 'ALL',
                label: 'ALL',
                value: 'ALL'
            }
        ]
    }

    configValues = configValues.concat(
        staticData?.feeCodeList?.map((ft) => ({
            'data-testid': ft,
            label: ft,
            value: ft
        }))
    )

    switch (scheduleFeeType) {
        case FEE_SCHEDULE_TYPE.GLOBAL_OVERRIDES:
            configValues = configValues.filter((ft) =>
                [FEE_TYPE.SSELL, FEE_TYPE.BBUY].includes(ft.value)
            )
            break
        case FEE_SCHEDULE_TYPE.CONTRACT_OVERRIDES:
            configValues = configValues.filter((ft) =>
                [
                    FEE_TYPE.SELLER,
                    FEE_TYPE.INTSELL,
                    FEE_TYPE.SELLBYS,
                    FEE_TYPE.SELLBUY,
                    FEE_TYPE.BUYER,
                    FEE_TYPE.INTBUY,
                    FEE_TYPE.SIMBFE
                ].includes(ft.value)
            )
            break
        case FEE_SCHEDULE_TYPE.NATIONAL_CONTRACTS:
            configValues = configValues.filter((ft) =>
                [
                    FEE_TYPE.SELLER,
                    FEE_TYPE.INTSELL,
                    FEE_TYPE.SELLBYS,
                    FEE_TYPE.SELLBUY,
                    FEE_TYPE.BUYER,
                    FEE_TYPE.INTBUY,
                    FEE_TYPE.SIMBFE
                ].includes(ft.value)
            )
            break
        case FEE_SCHEDULE_TYPE.LOCAL_DEALS:
            configValues = configValues.filter((ft) =>
                [
                    FEE_TYPE.SELLER,
                    FEE_TYPE.SLIST,
                    FEE_TYPE.INTSELL,
                    FEE_TYPE.SELLBYS,
                    FEE_TYPE.SELLBUY,
                    FEE_TYPE.BUYER,
                    FEE_TYPE.BLIST,
                    FEE_TYPE.INTBUY,
                    FEE_TYPE.SIMBFE
                ].includes(ft.value)
            )
            break
        case FEE_SCHEDULE_TYPE.CONTRACTS_BLD:
            configValues = configValues.filter((ft) =>
                [FEE_TYPE.SLIST, FEE_TYPE.BLIST].includes(ft.value)
            )
            break
        case FEE_SCHEDULE_TYPE.DEFAULTS:
            configValues = configValues.filter((ft) =>
                [
                    FEE_TYPE.SLIST,
                    FEE_TYPE.INTSELL,
                    FEE_TYPE.BLIST,
                    FEE_TYPE.INTBUY
                ].includes(ft.value)
            )
            break
        default:
            break
    }

    return (
        <Select
            label={label}
            data-testid={dataTestId}
            options={configValues}
            onChange={onChange}
            value={value}
            multiple={multiple}
            disabled={disabled}
            required={required}
            placeholder={placeholderText}
            name={name}
            displayDeselectOption={false}
        />
    )
}
