import { PatTextInput } from '../common/PatTextInput'
import { Grid } from '@interstate/components/Grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@interstate/components/Button'
import { Card } from '@interstate/components/Card'
import { TextArea } from '@interstate/components/TextArea'
import {
    postFeeEvent,
    postManualFee,
    removeManualFee
} from '../../service/manualService'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'
import { PatErrorAlert } from '../common/PatErrorAlert'
import {
    isEmptyNotNullString,
    isEmptyString,
    isNullOrEmptyString
} from '../../utils/commonValidations'

function FeeInputForm({
    buyer,
    seller,
    intBuy,
    intSell,
    notes,
    channel,
    onChange
}) {
    return (
        <>
            <Grid container spacing={2}>
                <Grid xs={3}>
                    <PatTextInput
                        label="Buyer"
                        name="buyer"
                        value={buyer}
                        onChange={onChange}
                        type="number"
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="Seller"
                        name="seller"
                        value={seller}
                        onChange={onChange}
                        type="number"
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTBUY"
                        name="intBuy"
                        value={intBuy}
                        onChange={onChange}
                        type="number"
                        disabled={channel !== 'Z'}
                    />
                </Grid>
                <Grid xs={3}>
                    <PatTextInput
                        label="INTSELL"
                        name="intSell"
                        value={intSell}
                        onChange={onChange}
                        type="number"
                        disabled={channel !== 'Z'}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <TextArea
                        label="Notes"
                        name="Notes"
                        maxLength={254}
                        value={notes}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

function ManualFeeActions({
    toggle,
    setToggle,
    save,
    revert,
    manualFees,
    submitButtonEnabled
}) {
    const disableRevertButton = useMemo(() => {
        return manualFees?.length === 0
    }, [manualFees])
    return (
        <div style={{ margin: '10px' }}>
            <Button
                sx={{
                    marginRight: '10px'
                }}
                disabled={!submitButtonEnabled}
                onClick={save}>
                Submit
            </Button>
            <Button
                sx={{
                    marginRight: '10px',
                    backgroundColor: 'darkred'
                }}
                buttonStyle={'danger'}
                disabled={disableRevertButton}
                onClick={revert}>
                Revert to Default
            </Button>
            <Button
                buttonStyle={'secondary'}
                onClick={() => {
                    setToggle(!toggle)
                }}>
                Cancel
            </Button>
        </div>
    )
}

export function ManualFeesForm({
    bucode,
    sblu,
    workOrder,
    vin,
    buyerNumber,
    sellerNumber,
    salePrice,
    saleType,
    channel,
    sbluAuditRecords,
    manualFees,
    manualFeesLoading,
    manualFeesError,
    repostData,
    setManualFeeRefresh,
    setManualFeeProcessing,
    setManualFeeUpdated
}) {
    const [toggle, setToggle] = useState(false)
    const [buyer, setBuyer] = useState(null)
    const [seller, setSeller] = useState(null)
    const [intBuy, setIntBuy] = useState(null)
    const [intSell, setIntSell] = useState(null)
    const [notes, setNotes] = useState(null)
    const [validationError, setValidationError] = useState(null)
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState(false)

    const authService = useAuth()
    const principal = authService.principal
    const user = principal?.user
    const role = principal?.role
    const show = role === PAT_ROLE.ADMIN || role === PAT_ROLE.BOC_EDIT

    const getAmount = useCallback(
        (attr) => {
            switch (attr) {
                case 'BUYER':
                    return buyer
                case 'SELLER':
                    return seller
                case 'INTBUY':
                    return intBuy
                case 'INTSELL':
                    return intSell
                default:
                    return null
            }
        },
        [buyer, seller, intBuy, intSell]
    )

    const getOriginalFee = useCallback(
        (attr) => {
            let d = sbluAuditRecords?.filter((fee) => fee.feeCategory === attr)
            if (d?.length > 0) {
                return {
                    fee: d[0]?.patCalculatedFee,
                    id: d[0].feeSchedulePartId
                }
            }
            return { fee: null, id: null }
        },
        [sbluAuditRecords]
    )

    const isFormDirty = useCallback(() => {
        let fieldsChanged = {}
        const attributes = ['BUYER', 'SELLER', 'INTBUY', 'INTSELL']
        attributes.forEach((attr) => {
            let newFee = getAmount(attr)
            let originalFee = getOriginalFee(attr)?.fee
            if (newFee && parseInt(newFee) !== originalFee) {
                fieldsChanged[attr] = 'changed'
            } else if (originalFee >= 0 && isEmptyNotNullString(newFee)) {
                fieldsChanged[attr] = 'changed'
            }
        })

        let originalNotes = null
        let newNotes = null
        if (manualFees && manualFees.length > 0) {
            originalNotes = manualFees[0]?.description
        }
        if (notes?.length === 0) {
            newNotes = null
        } else {
            newNotes = notes
        }

        if (newNotes !== originalNotes) {
            fieldsChanged.NOTES = 'changed'
        }

        let formDirty = false
        const changedKeys = Object.keys(fieldsChanged)
        changedKeys.forEach((changedKey) => {
            if (!formDirty && fieldsChanged[changedKey] === 'changed') {
                formDirty = true
            }
        })

        if (fieldsChanged.NOTES === 'changed') {
            formDirty = true
        }
        return formDirty
    }, [manualFees, getAmount, getOriginalFee, notes])

    useEffect(() => {
        if (!manualFees) {
            sbluAuditRecords.forEach((sbluAuditRecord) => {
                setValue(
                    sbluAuditRecord.feeCategory,
                    sbluAuditRecord?.patCalculatedFee
                )
            })
        }
        if (toggle) {
            let notes = null
            manualFees?.forEach((manualFee) => {
                setValue(manualFee.fee_category, manualFee.amount)

                if (!notes && manualFee?.description) {
                    notes = manualFee.description
                }
            })
            setNotes(notes)
        }
    }, [manualFees, sbluAuditRecords, toggle])

    useEffect(() => {
        if (
            isFormDirty() &&
            (!isNullOrEmptyString(buyer) ||
                !isNullOrEmptyString(seller) ||
                !isNullOrEmptyString(intBuy) ||
                !isNullOrEmptyString(intSell))
        ) {
            setSubmitButtonEnabled(true)
        } else {
            setSubmitButtonEnabled(false)
        }
    }, [buyer, seller, intBuy, intSell, notes, isFormDirty])

    const createDataObject = (attr) => {
        let d = manualFees.filter((fee) => fee.fee_category === attr)
        if (d?.length > 0) {
            return d[0]
        }
        return null
    }

    const setValue = (attr, value) => {
        switch (attr) {
            case 'BUYER':
                setBuyer(value)
                break
            case 'SELLER':
                setSeller(value)
                break
            case 'INTBUY':
                setIntBuy(value)
                break
            case 'INTSELL':
                setIntSell(value)
                break
            case 'NOTES':
                setNotes(value)
                break
            default:
        }
    }

    const onValidate = () => {
        if (notes?.length > 254) {
            setValidationError('Notes cannot exceed 254 characters')
            return false
        } else if (isEmptyString(notes)) {
            setValidationError('Notes is required')
            return false
        }

        setValidationError(null)
        return true
    }

    const onChange = (e) => {
        setValue(e?.target?.name?.toUpperCase(), e.target.value)
        // setSubmitButtonEnabled(isFormDirty())
    }

    const onSave = async () => {
        if (!onValidate()) {
            return
        }
        let promisedPosts = []
        const attributes = ['BUYER', 'SELLER', 'INTBUY', 'INTSELL']
        let removePromises = []
        attributes.forEach((attr) => {
            // if the new amount is empty, and a manual fee exists, remove it
            if (!getAmount(attr) || getAmount(attr) === '') {
                const matchedManualFee = manualFees?.find(
                    (manualFee) => manualFee.fee_category === attr
                )
                if (matchedManualFee) {
                    removePromises.push(removeManualFee(matchedManualFee.id))
                }
                return
            }

            let data = createDataObject(attr)
            if (data) {
                data.description = notes
                data.amount = getAmount(attr)
            } else {
                const { fee: originalFee, id: orignalFeeId } =
                    getOriginalFee(attr)

                data = {
                    bu_code: bucode,
                    sblu: sblu,
                    fee_category: attr,
                    sale_type: saleType,
                    seller: sellerNumber,
                    buyer: buyerNumber,
                    amount: getAmount(attr),
                    sale_price: salePrice,
                    created_by: user,
                    update_by: user,
                    description: notes,
                    original_amount: originalFee,
                    original_fee_id: orignalFeeId
                }
            }
            // console.log(data)
            //Call API to save
            promisedPosts.push(postManualFee(data))
        })

        await Promise.all(removePromises)
            .then((res) => {
                console.log('removed manual fees', res)
            })
            .catch((error) => {
                console.error(error)
            })

        await Promise.all(promisedPosts)
            .then((res) => {
                console.log(res)
                // delay postFeeEvent by 60 seconds
                // setTimeout(() => {
                //     postFeeEvent(repostData)
                // }, 60000)

                // send the event immediately
                postFeeEvent(repostData)

                setToggle(!toggle)
                setManualFeeUpdated(false)
                setManualFeeProcessing(true)
                setManualFeeRefresh(new Date().getTime())
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const onRevert = () => {
        let removePromises = []
        manualFees.forEach((fee) => {
            removePromises.push(removeManualFee(fee.id))
        })

        Promise.all(removePromises)
            .then((res) => {
                console.log(res)

                postFeeEvent(repostData)
                setToggle(!toggle)
                setManualFeeUpdated(false)
                setManualFeeProcessing(true)
                setManualFeeRefresh(new Date().getTime())
            })
            .catch((error) => {
                console.error(error)
            })
    }

    function onClickEditFees() {
        setToggle(!toggle)
    }

    return (
        <>
            {show && (
                <>
                    {!toggle && (
                        <Button onClick={onClickEditFees}>Edit Fees</Button>
                    )}
                    {toggle && !manualFeesLoading && (
                        <>
                            <Card
                                header={<h2>Edit Fees</h2>}
                                content={
                                    <>
                                        <PatErrorAlert
                                            error={validationError}
                                        />
                                        <PatErrorAlert
                                            error={manualFeesError}
                                        />
                                        <FeeInputForm
                                            buyer={buyer}
                                            seller={seller}
                                            intBuy={intBuy}
                                            intSell={intSell}
                                            notes={notes}
                                            channel={channel}
                                            onChange={onChange}
                                        />
                                    </>
                                }
                                footer={
                                    <ManualFeeActions
                                        toggle={toggle}
                                        setToggle={setToggle}
                                        save={onSave}
                                        revert={onRevert}
                                        manualFees={manualFees}
                                        submitButtonEnabled={
                                            submitButtonEnabled
                                        }
                                    />
                                }
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}
