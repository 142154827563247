import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    createUser,
    updateUser,
    useGetByUserId
} from '../service/configService'
import { useNavigate } from 'react-router-dom'
import { useStaticDataContext } from '../context/StaticDataContext'
import { populateAuctionOptions } from '../utils/sale.type.helper'
import { Container } from '../components/Container'
import { PageHeader } from '../components/PageHeader'
import { CheckBox } from '@interstate/components/CheckBox'
import { PatErrorAlert } from '../components/common/PatErrorAlert'
import { PatTextInput } from '../components/common/PatTextInput'
import { SubmitCancelBox } from '../components/common/SubmitCancelBox'
import { LocationComboBox } from '../components/common/LocationComboBox'
import { PatSelectInput } from '../components/common/PatSelectInput'

export function EditUserPermissionsView(props) {
    const params = useParams()

    const [username, setUsername] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState('VIEW')
    const [locationId, setLocationId] = useState([])
    const [enabled, setEnabled] = useState(true)
    // const [auctionOptions, setAuctionOptions] = useState([])

    const [savedError, setSavedError] = useState(false)

    const {
        value: user,
        loading: userLoading,
        error: userError
    } = useGetByUserId(params.id)

    const navigate = useNavigate()

    const staticData = useStaticDataContext()

    useEffect(() => {
        setUsername(user?.username)
        setFirstName(user?.firstName)
        setLastName(user?.lastName)
        setEmail(user?.email)
        setRole(user?.role)
        setEnabled(user?.enabled === true)
        let _auctionOptions = populateAuctionOptions(staticData.auctions, true)
        let locations = _auctionOptions.filter((option) =>
            user?.locationId?.split(',').includes(option.value)
        )
        setLocationId(locations)
        // setAuctionOptions(_auctionOptions)
    }, [user, staticData.auctions])

    const handleSubmit = (event) => {
        event.preventDefault()
        const userData = {
            username,
            firstName,
            lastName,
            email,
            role,
            locationId: locationId?.map((option) => option.value).join(','),
            enabled
        }
        if (params.id) {
            // console.log("Update user " + username);
            updateUser(params.id, userData)
                .then((data) => {
                    // console.log(data);
                    navigate('/config/user-permissions')
                })
                .catch((error) => {
                    setSavedError(true)
                    console.error(error)
                })
        } else {
            // console.log("Create user " + username);
            createUser(userData)
                .then((data) => {
                    // console.log(data);
                    navigate('/config/user-permissions')
                })
                .catch((error) => {
                    setSavedError(true)
                    console.error(error)
                })
        }
    }
    return (
        <Container>
            <PageHeader titleValue="User Permissions" />
            <PatErrorAlert error={savedError} text="Error saving user" />
            <PatErrorAlert error={userError} text="Error loading user" />
            {!userLoading && (
                <div>
                    <PatTextInput
                        label="Username"
                        value={username}
                        placeholder="name@coxautoinc.com"
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                    />

                    <PatTextInput
                        label="First Name"
                        value={firstName}
                        placeholder="John"
                        onChange={(e) => {
                            setFirstName(e.target.value)
                        }}
                    />

                    <PatTextInput
                        label="Last Name"
                        value={lastName}
                        placeholder="Smith"
                        onChange={(e) => {
                            setLastName(e.target.value)
                        }}
                    />

                    <PatTextInput
                        label="Email"
                        value={email}
                        placeholder="name@coxautoinc.com"
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                    />

                    <PatSelectInput
                        label="Role"
                        value={role}
                        onChange={(e) => {
                            setRole(e.target.value)
                        }}
                        options={[
                            { value: 'VIEW', label: 'View' },
                            { value: 'BOC_EDIT', label: 'Edit - BOC' },
                            {
                                value: 'PRICING_EDIT',
                                label: 'Edit - Pricing Team'
                            },
                            {
                                value: 'AUCTION_EDIT',
                                label: 'Edit - Auction Team'
                            },
                            { value: 'ADMIN', label: 'Admin' }
                        ]}
                    />

                    <LocationComboBox
                        value={locationId}
                        label="Locations"
                        multiple={true}
                        onChange={(e) => {
                            setLocationId(e.target.value)
                        }}
                    />

                    <CheckBox
                        id="enabled"
                        label="Enabled"
                        name="enabled"
                        checked={enabled}
                        sx={{
                            margin: '18px'
                        }}
                        onChange={(e) => {
                            console.log(e)
                            setEnabled(e.target.value.checked)
                        }}
                        value="1"
                    />
                    <SubmitCancelBox
                        onSubmit={handleSubmit}
                        onCancel={() => {
                            navigate('/config/user-permissions')
                        }}
                    />
                </div>
            )}
        </Container>
    )
}
