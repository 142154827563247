import { useNavigate } from 'react-router-dom'
import { React } from 'react'
import { Menu, useMenu } from '@interstate/components/Menu'
import { Button } from '@interstate/components/Button'
import { Grid } from '@interstate/components/Grid'
import { useAuth } from '../../auth/AuthProvider'
import { PAT_ROLE } from '../../resources/enum.constants'
import {
    FEE_SCHEDULE_STORAGE_KEY,
    PUBLISHED_FEE_SCHEDULE_STORAGE_KEY
} from '../../utils/constants'
import { deleteStorage } from '../../utils/storageService'

function MenuLayout() {
    const navigate = useNavigate()
    const bocMenuProps = useMenu()
    const configMenuProps = useMenu()
    const transactionMenuProps = useMenu()
    const devMenuProps = useMenu()
    const authService = useAuth()

    const handleClick = (e) => {
        navigate(e.target.value)
    }

    const configMenuOptions = () => {
        let options = [
            {
                label: 'Sale Type Mappings',
                value: '/config/sale-type-mappings'
            },
            { label: 'Configurations', value: '/config/auction-configs' },
            { label: 'Sale Types', value: '/config/sale-types' }
        ]
        if (authService?.principal?.role === 'ADMIN') {
            options.push({
                label: 'User Permissions',
                value: '/config/user-permissions'
            })
        }
        return options
    }

    const transactionMenuOptions = () => {
        let options = [
            {
                label: 'Search Transactions',
                value: '/boc/searchTransactions'
            }
        ]
        return options
    }

    const feeScheduleMenuOptions = () => {
        let options = []
        if (
            [
                PAT_ROLE.ADMIN,
                PAT_ROLE.AUCTION_EDIT,
                PAT_ROLE.BOC_EDIT,
                PAT_ROLE.PRICING_EDIT
            ].includes(authService?.principal?.role)
        ) {
            options.push({
                label: 'Add New Fee Schedule',
                value: '/boc/addFeeSchedule'
            })
            // options.push({ label: 'Rate Cards', value: '/boc/ratecards' })
            // options.push({
            //     label: 'Fee Schedule Status',
            //     value: '/boc/feeScheduleStatus'
            // })
        }
        options.push({
            label: 'Search Fee Schedules',
            value: '/boc/searchFee'
        })

        options.push({
            label: 'Calculate Fees',
            value: '/boc/calculateFees'
        })
        return options
    }

    function cleanUp() {
        deleteStorage(FEE_SCHEDULE_STORAGE_KEY)
        deleteStorage(PUBLISHED_FEE_SCHEDULE_STORAGE_KEY)
    }

    function logout() {
        cleanUp()
        window.location.href = '/auth/logout'
    }

    return (
        <Grid
            container
            borderBottom={'1px solid'}
            boxShadow={'0px 2px #dddddd'}
            marginBottom={'15px'}>
            <Grid container maxWidth={'1400px'} width={'100%'} marginX={'auto'}>
                <Grid>
                    <a href="/">
                        <img
                            src="/ca-logo.png"
                            alt="Cox Auto Logo"
                            style={{ height: '40px' }}
                        />
                    </a>
                </Grid>
                <Grid xsOffset={'auto'}>
                    <label buttonStyle="tertiary" onClick={function Ua() {}}>
                        Welcome {authService?.principal?.firstName}{' '}
                    </label>
                    <Button
                        buttonStyle="tertiary"
                        onClick={bocMenuProps.onClick}>
                        Fee Management
                    </Button>
                    <Menu
                        referenceProps={bocMenuProps.referenceProps}
                        onSelect={handleClick}
                        menuOptions={feeScheduleMenuOptions()}
                    />
                    <Button
                        buttonStyle={'tertiary'}
                        onClick={transactionMenuProps.onClick}>
                        Transactions
                    </Button>
                    <Menu
                        referenceProps={transactionMenuProps.referenceProps}
                        onSelect={handleClick}
                        menuOptions={transactionMenuOptions()}
                    />
                    <Button
                        buttonStyle="tertiary"
                        onClick={configMenuProps.onClick}>
                        Config
                    </Button>
                    <Menu
                        referenceProps={configMenuProps.referenceProps}
                        onSelect={handleClick}
                        menuOptions={configMenuOptions()}
                    />
                    <Button
                        buttonStyle="tertiary"
                        onClick={devMenuProps.onClick}>
                        Dev
                    </Button>
                    <Menu
                        referenceProps={devMenuProps.referenceProps}
                        onSelect={handleClick}
                        menuOptions={[
                            {
                                label: 'Get Fees Tool',
                                value: '/dev/GetFeesTool'
                            },
                            {
                                label: 'AuditTestSources',
                                value: '/dev/AuditTestSources'
                            },
                            {
                                label: 'Import AS400 Data 1:1',
                                value: '/dev/import1to1AS400Data'
                            },
                            { label: 'Clear Cache', value: '/dev/clearCache' }
                        ]}
                    />
                    <Button buttonStyle="tertiary" onClick={logout}>
                        Logout
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MenuLayout
