export function isManualFeeUpdated(sbluAuditRecords, manualFees) {
    let updateComplete = true
    if (sbluAuditRecords?.length > 0 && manualFees?.length > 0) {
        sbluAuditRecords.forEach((sbluAuditRecord) => {
            // get the manual fee that matches the fee category
            let manuallyEnteredFee = manualFees.find(
                (eachManualFee) =>
                    eachManualFee.fee_category === sbluAuditRecord.feeCategory
            )

            // check if the fee in auditTable is different from the manually entered fee
            if (
                updateComplete &&
                manuallyEnteredFee &&
                parseInt(manuallyEnteredFee.amount) !==
                    sbluAuditRecord.patCalculatedFee
            ) {
                updateComplete = false
            }
        })

        return updateComplete
    }
}
