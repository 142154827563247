import React from 'react'

export function LocalSpinner({ message }) {
    return (
        <div className="flex-container">
            <div className="localspinner"></div>&nbsp;
            <div className="localspinner-text">{message}</div>
        </div>
    )
}
