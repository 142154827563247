import React from 'react'
import { Table } from '@interstate/components/Table'
import { renderDateFromIsoString } from '../../utils/Formatters'
import { LocalSpinner } from '../common/LocalSpinner'
import { postFeeEvent } from '../../service/manualService'

export function PendingFeeTable({
    manualFees,
    repostData,
    manualFeeUpdated,
    manualFeeProcessing,
    setManualFeeUpdated,
    setManualFeeProcessing
}) {
    // console.log('PendingFeeTable manualFeeUpdated', manualFeeUpdated)
    // console.log('PendingFeeTable manualFeeProcessing', manualFeeProcessing)

    // function isManualFeeUpdateComplete() {
    //     return isManualFeeUpdated(sbluAuditRecords, manualFees)
    // }

    function replayMessage() {
        setManualFeeUpdated(false)
        setManualFeeProcessing(true)
        postFeeEvent(repostData)
    }

    return (
        <>
            <h2>Pending Fees</h2>
            {!manualFeeUpdated && manualFeeProcessing && (
                <LocalSpinner message={'Processing'} />
            )}
            {!manualFeeUpdated && !manualFeeProcessing && (
                <span>
                    Updates are taking longer than expected. Click&nbsp;
                    <strong className="fakeLinkDefault" onClick={replayMessage}>
                        here
                    </strong>
                    &nbsp;to try again.
                </span>
            )}
            {manualFeeProcessing && manualFees?.length === 0 && (
                <span>Reverting fees</span>
            )}
            {manualFees && !manualFeeUpdated && (
                <Table
                    tableLayout={'auto'}
                    columns={[
                        {
                            title: 'Fee Category',
                            render: (item) => <span>{item.fee_category}</span>
                        },
                        {
                            title: 'Amount',
                            render: (item) => <span>{item.amount}</span>
                        },
                        {
                            title: 'Action',
                            render: (item) => <span>Override</span>
                        },
                        {
                            title: 'Submitted On',
                            render: (item) => (
                                <span>
                                    {renderDateFromIsoString(
                                        item.created,
                                        'MM/DD/YYYY'
                                    )}
                                </span>
                            )
                        }
                    ]}
                    data={manualFees}
                />
            )}
        </>
    )
}
