import { ComboBox } from '@interstate/components/ComboBox'
import { useStaticDataContext } from '../../context/StaticDataContext'
import { auctionOptionsUtil } from '../../utils/auction.util'
import { PatInputStyle } from './PatInputStyle'
import { useAuth } from '../../auth/AuthProvider'

export function LocationComboBox({
    value,
    onChange,
    multiple = true,
    disabled = false,
    all = false,
    filterOut = [],
    auctionEditFilter = false,
    dataTestId,
    required
}) {
    // console.log('value in LocationComboBox', value)
    const staticData = useStaticDataContext()
    const authService = useAuth()
    const auctionOptions =
        auctionEditFilter === true
            ? auctionOptionsUtil(staticData, all, authService.principal)
            : auctionOptionsUtil(staticData, all, null)

    let displayValue = value
    if (value && typeof value[0] === 'string') {
        displayValue = auctionOptions?.filter((ao) => value.includes(ao.value))
    }

    let filteredOptions = auctionOptions?.filter(
        (ao) => !filterOut.includes(ao.value)
    )

    function onLocationChange(event) {
        if (event.target.value?.length > 0) {
            let allOptionSelected =
                event.target.value?.filter(
                    (eachOption) => eachOption.value === 'ALL'
                )?.length > 0

            // if ALL is selected, along with other options, remove ALL
            if (allOptionSelected && event.target.value?.length > 1) {
                event.target.value = event.target.value.filter(
                    (eachOption) => eachOption.value !== 'ALL'
                )
            }
        }

        onChange(event)
    }

    return (
        <ComboBox
            label={multiple ? 'Location(s)' : 'Location'}
            data-testid={dataTestId}
            options={filteredOptions}
            onChange={onLocationChange}
            value={displayValue}
            multiple={multiple}
            disabled={disabled}
            sx={PatInputStyle}
            required={required}
        />
    )
}
