import React, { useEffect, useMemo, useState } from 'react'
import { getVehicleAuctionSblu } from '../../service/auctionVehicleDataService'
import { getPossibleFeeSchedules } from '../../service/feeScheduleService'
import { VehicleNotesTable } from '../VehicleNotesTable'
import { PossibleFeeTable } from '../tables/PossibleFeeTable'
import { AppliedFeeTable } from '../tables/AppliedFeeTable'
import { AuctionVehicleDataTable } from '../tables/AuctionVehicleDataTable'
import { ManualFeesForm } from '../forms/ManualFeesForm'
import { Button } from '@interstate/components/Button'
import {
    ArrowPathIcon,
    ArrowUturnLeftIcon,
    PlayCircleIcon
} from '@interstate/components/Icons'
import { postFeeEvent, useGetManualFees } from '../../service/manualService'
import { PatWarningAlert } from '../common/PatWarningAlert'
import { useAuditSearch, getAuditRecords } from '../../service/auditService'
import { PendingFeeTable } from '../tables/PendingFeeTable'
import { isManualFeeUpdated } from '../../utils/audit.helper'

export function IndividualAudit({ auditData, back }) {
    const [vehicleData, setVehicleData] = useState({})
    const [feeSchedules, setFeeSchedules] = useState([])
    const [highlightedRow, setHighlightedRow] = useState([])
    const [refresh, setRefresh] = useState(new Date().getTime())
    const [manualFeeRefresh, setManualFeeRefresh] = useState()
    const [manualFeeUpdated, setManualFeeUpdated] = useState(false)
    const [manualFeeProcessing, setManualFeeProcessing] = useState(false)

    let singleAuditRow = auditData && auditData.length > 0 ? auditData[0] : null
    let testSource = singleAuditRow?.patTestSource
    let sblu = singleAuditRow?.sblu
    let locationId = useMemo(() => {
        return [singleAuditRow?.buCode]
    }, [singleAuditRow])
    let universalKey = useMemo(() => {
        return `${singleAuditRow?.saleYear}-${singleAuditRow?.saleNumber}-${singleAuditRow?.lane}-${singleAuditRow?.runNumber}`
    }, [singleAuditRow])

    // console.log('singleAuditRow', singleAuditRow)
    const {
        value: sbluAuditRecords,
        loading: sbluAuditRecordsLoading,
        error: sbluAuditRecordsError
    } = useAuditSearch({ testSource, sblu, locationId, universalKey, refresh })

    const {
        value: manualFees,
        loading: manualLoading,
        error: manualError
    } = useGetManualFees(locationId, sblu, refresh, manualFeeRefresh)

    useEffect(() => {
        let attempt = 1
        let maxRetries = 10
        let timeoutId = null

        // setup the flags for manualFee
        if (sbluAuditRecords?.length > 0 && manualFees?.length > 0) {
            if (isManualFeeUpdated(sbluAuditRecords, manualFees)) {
                // console.log('matched manual fee updated')
                setManualFeeUpdated(true)
                setManualFeeProcessing(false)
            }
        }

        async function queryAuditRecords() {
            // query the audit records and match with manualfee to see if the fees have been updated
            if (
                manualFeeProcessing &&
                manualFees?.length > 0 &&
                attempt <= maxRetries
            ) {
                const localSbluAuditRecords = await getAuditRecords({
                    testSource,
                    sblu,
                    locationId,
                    universalKey,
                    hideSpinner: true
                })
                let localManualFeeUpdated = isManualFeeUpdated(
                    localSbluAuditRecords,
                    manualFees
                )
                // if manualFee is updated, then set the flag and stop the processing
                if (localManualFeeUpdated) {
                    setManualFeeUpdated(true)
                    setManualFeeProcessing(false)
                    setRefresh(new Date().getTime())
                }
                // if manualFee is not updated, then attempt again in 1 second
                if (!localManualFeeUpdated && attempt <= maxRetries) {
                    timeoutId = setTimeout(() => {
                        queryAuditRecords()
                    }, 1000)
                }
                attempt++
            }
            if (manualFeeUpdated || attempt >= maxRetries) {
                setManualFeeProcessing(false)
            }
        }

        queryAuditRecords()

        // Cleanup function to clear the timeout on unmount or re-render
        return () => clearTimeout(timeoutId)
    }, [
        manualFees,
        sbluAuditRecords,
        manualFeeProcessing,
        locationId,
        manualFeeUpdated,
        sblu,
        testSource,
        universalKey
    ])

    // useEffect(() => {
    //     console.log('in useEffect manualFeeUpdated', manualFeeUpdated)
    //     console.log('in useEffect manualFeeProcessing', manualFeeProcessing)
    //     if (!manualFeeProcessing && manualFeeUpdated) {
    //     }
    // }, [manualFeeUpdated, manualFeeProcessing])

    useEffect(() => {
        if (!singleAuditRow) return
        let av = singleAuditRow?.buCode
        let sblu = singleAuditRow?.sblu
        getVehicleAuctionSblu(av, sblu)
            .then((d) => {
                setVehicleData(d)
            })
            .catch((error) => {
                setVehicleData(error.response.data)
            })

        let highlight = []

        auditData.forEach((fee) => {
            if (!highlight.includes(fee.feeSchedulePartId)) {
                highlight.push(fee.feeSchedulePartId)
            }
        })
        setHighlightedRow(highlight)
    }, [singleAuditRow, auditData, refresh])

    useEffect(() => {
        if (!singleAuditRow) return

        let av = singleAuditRow?.buCode

        let customerIds = [
            singleAuditRow?.seller,
            singleAuditRow?.buyer,
            singleAuditRow?.sellerGroup,
            singleAuditRow?.buyerGroup,
            singleAuditRow?.sellerSubGroup,
            singleAuditRow?.buyerSubGroup
        ]
        if (
            vehicleData?.body?.saleDate &&
            vehicleData?.body?.saleDate !== '0'
        ) {
            getPossibleFeeSchedules(
                av,
                customerIds,
                singleAuditRow.saleType,
                vehicleData?.body?.saleDate
            )
                .then((d) => {
                    setFeeSchedules(d)
                })
                .catch((error) => {
                    setFeeSchedules(error?.response?.data)
                })
        }
    }, [singleAuditRow, vehicleData])
    // console.log(vehicleData)
    const replay = () => {
        const event = {
            bucode: singleAuditRow?.buCode,
            sblu: singleAuditRow?.sblu,
            workOrder: singleAuditRow?.workOrderNumber,
            vin: vehicleData?.body?.vin,
            seller: vehicleData?.body?.seller,
            buyer: vehicleData?.body?.buyer
        }
        let error = false
        Object.keys(event).forEach((key) => {
            if (event[key] === null || event[key] === undefined) {
                error = true
                console.log('error missing event attribute: ', key)
            }
        })

        if (!error) {
            postFeeEvent(event)
        }
    }

    function showPendingTable() {
        if (manualFees?.length > 0 && !manualFeeUpdated) {
            return true
        } else if (manualFeeProcessing) {
            return true
        }
        return false
    }

    function getDataForRepost() {
        const event = {
            bucode: singleAuditRow?.buCode,
            sblu: singleAuditRow?.sblu,
            workOrder: singleAuditRow?.workOrderNumber,
            vin: vehicleData?.body?.vin,
            buyer: vehicleData?.body?.buyer,
            seller: vehicleData?.body?.seller
        }
        return event
    }

    return (
        <>
            <div>
                {back && (
                    <Button
                        buttonStyle="tertiary"
                        data-testid={'back-icon'}
                        onClick={back}
                        startIcon={<ArrowUturnLeftIcon />}>
                        Back
                    </Button>
                )}
                {vehicleData?.body?.buyerFinalize !== 'Y' && (
                    <>
                        <Button
                            buttonStyle="tertiary"
                            data-testid={'refresh-icon'}
                            onClick={() => {
                                setRefresh(new Date().getTime())
                            }}
                            startIcon={<ArrowPathIcon />}>
                            Refresh
                        </Button>
                        <Button
                            buttonStyle="tertiary"
                            data-testid={'replay-icon'}
                            onClick={replay}
                            startIcon={<PlayCircleIcon />}
                            aria-label="Replay Event"
                            title="Replay Event">
                            Replay
                        </Button>
                    </>
                )}
            </div>
            <AuctionVehicleDataTable
                initData={singleAuditRow}
                vehicleData={vehicleData}
            />

            <AppliedFeeTable
                sbluAuditRecords={sbluAuditRecords}
                sbluAuditRecordsLoading={sbluAuditRecordsLoading}
                sbluAuditRecordsError={sbluAuditRecordsError}
                manualFees={manualFees}
            />

            {showPendingTable() && (
                <PendingFeeTable
                    manualFees={manualFees}
                    sbluAuditRecords={sbluAuditRecords}
                    repostData={getDataForRepost()}
                    refresh={refresh}
                    manualFeeUpdated={manualFeeUpdated}
                    manualFeeProcessing={manualFeeProcessing}
                    setManualFeeUpdated={setManualFeeUpdated}
                    setManualFeeProcessing={setManualFeeProcessing}
                />
            )}

            <div>
                <PatWarningAlert
                    error={vehicleData?.body?.buyerFinalize === 'Y'}
                    title="Transaction Finalized"
                    text="Fees can only be updated in AS-400 and Oracle."
                />
            </div>
            {vehicleData?.body?.buyerFinalize !== 'Y' &&
                sbluAuditRecords?.length > 0 && (
                    <ManualFeesForm
                        bucode={singleAuditRow?.buCode}
                        sblu={singleAuditRow?.sblu}
                        workOrder={singleAuditRow?.workOrderNumber}
                        vin={vehicleData?.body?.vin}
                        buyerNumber={vehicleData?.body?.buyer}
                        sellerNumber={vehicleData?.body?.seller}
                        salePrice={vehicleData?.body?.salePrice}
                        saleType={singleAuditRow?.saleType}
                        channel={vehicleData?.body?.saleChannel}
                        feeData={auditData}
                        sbluAuditRecords={sbluAuditRecords}
                        manualFees={manualFees}
                        manualFeesLoading={manualLoading}
                        manualFeesError={manualError}
                        repostData={getDataForRepost()}
                        setRefresh={setRefresh}
                        setManualFeeRefresh={setManualFeeRefresh}
                        setManualFeeProcessing={setManualFeeProcessing}
                        setManualFeeUpdated={setManualFeeUpdated}
                    />
                )}

            <h2>Eligible Seller Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={['SSELL', 'SELLER', 'SLIST']}
                highlightedRow={highlightedRow}
            />

            <h2>Eligible Buyer Fees</h2>
            <PossibleFeeTable
                fees={feeSchedules}
                filterCriteria={[
                    'BUYER',
                    'BLIST',
                    'BBUY',
                    'SELLBUY',
                    'SELLBYS'
                ]}
                highlightedRow={highlightedRow}
            />

            {vehicleData?.body?.saleChannel === 'Z' && (
                <>
                    <h2>Eligible Simulcast Fees</h2>
                    <PossibleFeeTable
                        fees={feeSchedules}
                        filterCriteria={[
                            'INTBUY',
                            'INTSELL',
                            'SIMBFE',
                            'SIMBUY'
                        ]}
                        highlightedRow={highlightedRow}
                    />
                </>
            )}

            {singleAuditRow && (
                <>
                    <h2>Vehicle Notes</h2>
                    <VehicleNotesTable
                        auction={singleAuditRow?.buCode}
                        workOrderNumber={singleAuditRow.workOrderNumber}
                    />
                </>
            )}
        </>
    )
}
