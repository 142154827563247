import axios from 'axios'
import { useAsyncValue } from '../utils/CustomHooksUtil'

export function useGetManualFees(bucode, sblu, refresh, manualFeeRefresh) {
    return useAsyncValue(() => {
        if (!bucode || !sblu) {
            return Promise.resolve(null)
        }
        return axios
            .get(`/api/manual/bucode/${bucode}/sblu/${sblu}`)
            .then(({ data }) => data)
    }, [bucode, sblu, refresh, manualFeeRefresh])
}

export function postManualFee(data) {
    return axios.post('/api/manual', data)
}

export function postFeeEvent(data) {
    return axios.post('/api/manual/processFeeEvent', data)
}

export function removeManualFee(id) {
    return axios.delete(`/api/manual/${id}`)
}
